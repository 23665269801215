import { useEffect, useState } from "react";

const useBreakpoint = (breakpoint = 576) => {
	const [isMobile, setIsMobile] = useState(window.innerWidth <= breakpoint);

	const handleWindowSizeChange = () => {
		setIsMobile(window.innerWidth <= breakpoint);
	};

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, [breakpoint]);

	return isMobile;
};

export default useBreakpoint;
