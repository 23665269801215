import { Head } from "@inertiajs/react";
import { Container } from "react-bootstrap";
import CookieBar from "@/Components/CookieBar/index.js";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Contact from "@/Components/Contact/Contact.jsx";
import Header from "@/Components/Header/Header.jsx";
import Footer from "@/Components/Footer/Footer.jsx";

export default function DefaultLayout({ children }) {
	const [displayCookieBar, setDisplayCookieBar] = useState(false);

	useEffect(() => {
		if (Cookies.get("cmplz_banner-status") !== "dismissed") {
			setDisplayCookieBar(true);
		}
	}, []);

	return (
		<>
			<Head title="Moje závěť - Poslední věta" />

			<div className="layout">
				<Contact />
				<Header />

				<div className="layout__body">
					<Container>{children}</Container>
				</div>

				<Footer />
			</div>

			{displayCookieBar && (
				<CookieBar
					onSubmit={() => {
						setDisplayCookieBar(false);
					}}
				/>
			)}
		</>
	);
}
