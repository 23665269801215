import { useState } from "react";
import Cookies from "js-cookie";

export default function CookieBar({ onSubmit }) {
	const [displayPreferences, setDisplayPreferences] = useState(false);
	const [statistics, setStatistics] = useState(false);
	const [marketing, setMarketing] = useState(false);

	const handle = ({
		bannerStatus,
		consentedServices,
		functional,
		marketing,
		policyId,
		preferences,
		statistics,
	}) => {
		Cookies.set("cmplz_banner-status", bannerStatus);
		Cookies.set("cmplz_consented_services", consentedServices);
		Cookies.set("cmplz_functional", functional);
		Cookies.set("cmplz_marketing", marketing);
		Cookies.set("cmplz_policy_id", policyId);
		Cookies.set("cmplz_preferences", preferences);
		Cookies.set("cmplz_statistics", statistics);

		onSubmit();
	};

	return (
		<div id="cmplz-cookiebanner-container">
			<div
				className="cmplz-cookiebanner banner-1 banner-a optin cmplz-bottom-left cmplz-categories-type-view-preferences cmplz-show"
				aria-modal="true"
				data-nosnippet="true"
				role="dialog"
				aria-live="polite"
				aria-labelledby="cmplz-header-1-optin"
				aria-describedby="cmplz-message-1-optin"
			>
				<div className="cmplz-header">
					<div className="cmplz-logo"></div>
					<div className="cmplz-title" id="cmplz-header-1-optin">
						Spravovat Souhlas
					</div>
					<div
						className="cmplz-close"
						tabIndex="0"
						role="button"
						aria-label="Zavřít dialogové okno"
					>
						<svg
							aria-hidden="true"
							focusable="false"
							data-prefix="fas"
							data-icon="times"
							className="svg-inline--fa fa-times fa-w-11"
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 352 512"
						>
							<path
								fill="currentColor"
								d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
							></path>
						</svg>
					</div>
				</div>

				<div className="cmplz-divider cmplz-divider-header"></div>
				<div className="cmplz-body">
					<div className="cmplz-message" id="cmplz-message-1-optin">
						Abychom poskytli co nejlepší služby, používáme k
						ukládání a/nebo přístupu k informacím o zařízení,
						technologie jako jsou soubory cookies. Souhlas s těmito
						technologiemi nám umožní zpracovávat údaje, jako je
						chování při procházení nebo jedinečná ID na tomto webu.
						Nesouhlas nebo odvolání souhlasu může nepříznivě
						ovlivnit určité vlastnosti a funkce.
					</div>
					{displayPreferences && (
						<div className="cmplz-categories">
							<details className="cmplz-category cmplz-functional">
								<summary>
									<span className="cmplz-category-header">
										<span className="cmplz-category-title">
											Funkční
										</span>
										<span className="cmplz-always-active">
											<span className="cmplz-banner-checkbox">
												<input
													type="checkbox"
													id="cmplz-functional-optin"
													data-category="cmplz_functional"
													className="cmplz-consent-checkbox cmplz-functional"
													size="40"
													value="1"
												/>
												<label
													className="cmplz-label"
													htmlFor="cmplz-functional-optin"
													tabIndex="0"
												>
													<span className="screen-reader-text">
														Funkční
													</span>
												</label>
											</span>
											Vždy aktivní{" "}
										</span>
										<span className="cmplz-icon cmplz-open">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 448 512"
												height="18"
											>
												<path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"></path>
											</svg>
										</span>
									</span>
								</summary>
								<div className="cmplz-description">
									<span className="cmplz-description-functional">
										Technické uložení nebo přístup je
										nezbytně nutný pro legitimní účel
										umožnění použití konkrétní služby,
										kterou si odběratel nebo uživatel
										výslovně vyžádal, nebo pouze za účelem
										provedení přenosu sdělení
										prostřednictvím sítě elektronických
										komunikací.
									</span>
								</div>
							</details>

							<details className="cmplz-category cmplz-preferences">
								<summary>
									<span className="cmplz-category-header">
										<span className="cmplz-category-title">
											Předvolby
										</span>
										<span className="cmplz-banner-checkbox">
											<input
												type="checkbox"
												id="cmplz-preferences-optin"
												data-category="cmplz_preferences"
												className="cmplz-consent-checkbox cmplz-preferences"
												size="40"
												value="1"
											/>
											<label
												className="cmplz-label"
												htmlFor="cmplz-preferences-optin"
												tabIndex="0"
											>
												<span className="screen-reader-text">
													Předvolby
												</span>
											</label>
										</span>
										<span className="cmplz-icon cmplz-open">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 448 512"
												height="18"
											>
												<path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" />
											</svg>
										</span>
									</span>
								</summary>
								<div className="cmplz-description">
									<span className="cmplz-description-preferences">
										Technické uložení nebo přístup je
										nezbytný pro legitimní účel ukládání
										preferencí, které nejsou požadovány
										odběratelem nebo uživatelem.
									</span>
								</div>
							</details>

							<details className="cmplz-category cmplz-statistics">
								<summary>
									<span className="cmplz-category-header">
										<span className="cmplz-category-title">
											Statistiky
										</span>
										<span className="cmplz-banner-checkbox">
											<input
												type="checkbox"
												id="cmplz-statistics-optin"
												data-category="cmplz_statistics"
												className="cmplz-consent-checkbox cmplz-statistics"
												size="40"
												value="1"
												onChange={(e) => {
													setStatistics(
														e.target.checked,
													);
												}}
											/>
											<label
												className="cmplz-label"
												htmlFor="cmplz-statistics-optin"
												tabIndex="0"
											>
												<span className="screen-reader-text">
													Statistiky
												</span>
											</label>
										</span>
										<span className="cmplz-icon cmplz-open">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 448 512"
												height="18"
											>
												<path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" />
											</svg>
										</span>
									</span>
								</summary>
								<div className="cmplz-description">
									<span className="cmplz-description-statistics">
										Technické uložení nebo přístup, který se
										používá výhradně pro statistické účely.
									</span>
									<span className="cmplz-description-statistics-anonymous">
										Technické uložení nebo přístup, který se
										používá výhradně pro anonymní
										statistické účely. Bez předvolání,
										dobrovolného plnění ze strany vašeho
										Poskytovatele internetových služeb nebo
										dalších záznamů od třetí strany nelze
										informace, uložené nebo získané pouze
										pro tento účel, obvykle použít k vaší
										identifikaci.
									</span>
								</div>
							</details>
							<details className="cmplz-category cmplz-marketing">
								<summary>
									<span className="cmplz-category-header">
										<span className="cmplz-category-title">
											Marketing
										</span>
										<span className="cmplz-banner-checkbox">
											<input
												type="checkbox"
												id="cmplz-marketing-optin"
												data-category="cmplz_marketing"
												className="cmplz-consent-checkbox cmplz-marketing"
												size="40"
												value="1"
												onChange={(e) => {
													setMarketing(
														e.target.checked,
													);
												}}
											/>
											<label
												className="cmplz-label"
												htmlFor="cmplz-marketing-optin"
												tabIndex="0"
											>
												<span className="screen-reader-text">
													Marketing
												</span>
											</label>
										</span>
										<span className="cmplz-icon cmplz-open">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 448 512"
												height="18"
											>
												<path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"></path>
											</svg>
										</span>
									</span>
								</summary>
								<div className="cmplz-description">
									<span className="cmplz-description-marketing">
										Technické uložení nebo přístup je nutný
										k vytvoření uživatelských profilů za
										účelem zasílání reklamy nebo sledování
										uživatele na webových stránkách nebo
										několika webových stránkách pro podobné
										marketingové účely.
									</span>
								</div>
							</details>
						</div>
					)}
				</div>

				<div className="cmplz-links cmplz-information">
					<a
						className="cmplz-link cmplz-manage-options cookie-statement"
						href="https://www.posledniveta.cz/cookies/#cmplz-manage-consent-container"
						data-relative_url="#cmplz-manage-consent-container"
					>
						Spravovat možnosti
					</a>
					<a
						className="cmplz-link cmplz-manage-third-parties cookie-statement"
						href="https://www.posledniveta.cz/cookies/#cmplz-cookies-overview"
						data-relative_url="#cmplz-cookies-overview"
					>
						Manage services
					</a>
					<a
						className="cmplz-link cmplz-manage-vendors tcf cookie-statement"
						href="https://www.posledniveta.cz/cookies/#cmplz-tcf-wrapper"
						data-relative_url="#cmplz-tcf-wrapper"
					>
						Manage vendor_count vendors
					</a>
					<a
						className="cmplz-link cmplz-external cmplz-read-more-purposes tcf"
						target="_blank"
						rel="noopener noreferrer nofollow"
						href="https://cookiedatabase.org/tcf/purposes/"
					>
						Přečtěte si více o těchto účelech
					</a>
				</div>

				<div className="cmplz-divider cmplz-footer"></div>

				<div className="cmplz-buttons">
					<button
						className="cmplz-btn cmplz-accept"
						onClick={() => {
							handle({
								bannerStatus: "dismissed",
								consentedServices: "",
								functional: "allow",
								marketing: "allow",
								policyId: 38,
								preferences: "allow",
								statistics: "allow",
							});
						}}
					>
						Příjmout
					</button>
					<button
						className="cmplz-btn cmplz-deny"
						onClick={() => {
							handle({
								bannerStatus: "dismissed",
								consentedServices: "",
								functional: "allow",
								marketing: "deny",
								policyId: 38,
								preferences: "deny",
								statistics: "deny",
							});
						}}
					>
						Odmítnout
					</button>
					{!displayPreferences ? (
						<button
							className="cmplz-btn cmplz-view-preferences"
							onClick={() => {
								setDisplayPreferences(true);
							}}
						>
							Zobrazit předvolby
						</button>
					) : null}
					{displayPreferences ? (
						<button
							className="cmplz-btn cmplz-save-preferences"
							onClick={() => {
								handle({
									bannerStatus: "dismissed",
									consentedServices: "",
									functional: "allow",
									marketing: marketing ? "allow" : "deny",
									policyId: 38,
									preferences: "allow",
									statistics: statistics ? "allow" : "deny",
								});
							}}
						>
							Uložit předvolby
						</button>
					) : null}
				</div>

				<div className="cmplz-links cmplz-documents">
					<a
						className="cmplz-link cookie-statement"
						href="https://www.posledniveta.cz/cookies/"
						data-relative_url=""
					>
						Více informací o cookies
					</a>
					<a
						className="cmplz-link privacy-statement cmplz-hidden"
						href="#"
						data-relative_url=""
					></a>
					<a
						className="cmplz-link impressum cmplz-hidden"
						href="#"
						data-relative_url=""
					></a>
				</div>
			</div>
		</div>
	);
}
