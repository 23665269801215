import Key from "@/Components/Icons/Key.jsx";
import User from "@/Components/Icons/User.jsx";
import Sliders from "@/Components/Icons/Sliders.jsx";
import Users from "@/Components/Icons/Users.jsx";
import SackDollar from "@/Components/Icons/SackDollar.jsx";
import FileLines from "@/Components/Icons/FileLines.jsx";
import Hand from "@/Components/Icons/Hand.jsx";
import Pen from "@/Components/Icons/Pen.jsx";
import CartShopping from "@/Components/Icons/CartShopping.jsx";
import { useForm, usePage } from "@inertiajs/react";
import { useAppContext } from "@/Context/AppContext.jsx";
import Logout from "@/Components/Icons/Logout.jsx";
import { Button, Modal } from "react-bootstrap";
import React, { useState } from "react";

const STEPS_CONST = [
	{
		id: 0,
		key: "LOGIN",
	},
	{
		id: 1,
		key: "PERSONAL_INFORMATION",
	},
	{
		id: 2,
		key: "TYPE",
	},
	{
		id: 3,
		key: "INHERITORS",
	},
	{
		id: 4,
		key: "REAL_ESTATES",
	},
	{
		id: 4,
		key: "COMPANIES",
	},
	{
		id: 4,
		key: "FINANCIAL_RESOURCES",
	},
	{
		id: 4,
		key: "OTHER_PROPERTIES",
	},
	{
		id: 4,
		key: "PROPERTY_DIVISION",
	},
	{
		id: 5,
		key: "UNSPECIFIED_PROPERTY",
	},
	{
		id: 6,
		key: "LAST_SENTENCE",
	},
	{
		id: 7,
		key: "CART",
	},
	{
		id: 8,
		key: "SUMMARY",
	},
];

export default function Stepper() {
	const Icon = ({ icon }) => {
		return icon;
	};

	const { id, data, activeStep, auth } = usePage().props;
	const { currentStep } = useAppContext();
	const [showModal, setShowModal] = useState(false);
	const { post } = useForm();

	const handleModalClose = () => setShowModal(false);
	const handleModalShow = () => setShowModal(true);

	const handleLogout = () => {
		post(window.route("logout"));
		handleModalClose();
	};

	const steps = [
		{
			key: "login",
			href: window.route("login"),
			text: "Přihlášení",
			icon: <Key />,
			completed: false,
			active: true,
		},
		{
			key: "personal_information",
			href: id && window.route("testament.personalInformation", { id: id }),
			text: "Osobní údaje",
			icon: <User />,
			completed: false,
			active: false,
		},
		{
			key: "type",
			href: id && window.route("testament.type", { id: id }),
			text: "Typ závěti",
			icon: <Sliders />,
			completed: false,
			active: false,
		},
		{
			key: "inheritors",
			href: id && window.route("testament.inheritors", { id: id }),
			text: "Dědicové",
			icon: <Users />,
			completed: false,
			active: false,
		},
		{
			key: "property",
			href: id && window.route("testament.properties.realEstates", { id: id }),
			text: "Majetek",
			icon: <SackDollar />,
			completed: false,
			active: false,
		},
		{
			key: "property_division",
			href: id && window.route("testament.propertyDivision", { id: id }),
			text: "Rozdělení majetku",
			icon: <Hand />,
			completed: false,
			active: false,
		},
		{
			key: "last_sentence",
			href: id && window.route("testament.lastSentence", { id: id }),
			text: "Poslední věta",
			icon: <Pen />,
			completed: false,
			active: false,
		},
		{
			key: "cart",
			href: id && window.route("testament.cart", { id: id }),
			text: "Košík",
			icon: <CartShopping />,
			completed: false,
			active: false,
		},
		{
			key: "summary",
			href: id && window.route("testament.summary", { id: id }),
			text: "Moje závěť",
			icon: <FileLines />,
			completed: false,
			active: false,
		},
	];

	return (
		<div className="stepper">
			<ul className="stepper__list d-flex align-items-center">
				{steps.map(
					(step, index) =>
						!(data?.type === "PERCENTAGE" && step?.key === "property") && (
							<li
								key={index}
								className={`stepper__item ${
									steps.findIndex((o) => o.key === currentStep) > index
										? "stepper__item--completed"
										: ""
								} ${currentStep === step?.key ? "stepper__item--active" : ""}`}
							>
								{index <=
									STEPS_CONST.find((item) => item?.key === activeStep)?.id &&
								step?.key !== "login" &&
								step?.key !== "summary" &&
								!(data?.type === "PERCENTAGE" && step?.key === "property") &&
								!(step?.key === "summary" && step?.active) ? (
									<a href={step?.href}>
										<span className="stepper__icon" title={step?.text}>
											{step?.key !== "last_sentence" ? (
												<Icon icon={step?.icon} />
											) : (
												<img
													src="/posledniveta_small.png"
													width="20"
													height="20"
												/>
											)}
										</span>
										<span className="stepper__text">{step?.text}</span>
									</a>
								) : (
									<>
										<span className="stepper__icon" title={step?.text}>
											{step?.key !== "last_sentence" ? (
												<Icon icon={step?.icon} />
											) : (
												<img
													src="/posledniveta_small.png"
													width="20"
													height="20"
												/>
											)}
										</span>
										<span className="stepper__text">{step?.text}</span>
									</>
								)}
							</li>
						),
				)}
				{auth.user && (
					<a href="#" title="Odhlásit se" onClick={handleModalShow}>
						<li className="stepper__icon stepper__logout">
							<Logout />
						</li>
					</a>
				)}
			</ul>

			<Modal show={showModal} size="lg">
				<div className="p-4">
					<p className="mt-3 fs-5">
						Bezpečně vás odhlásíme. Pro pokračování se přihlaste stejnou
						e-mailovou adresou. Neuložené změny budou ztraceny
					</p>

					<div className="d-flex mt-5 justify-content-end align-items-center">
						<a href="#" className="me-4" onClick={handleModalClose}>
							Pokračovat
						</a>
						<Button
							variant="primary"
							size="lg"
							className="me-3"
							onClick={handleLogout}
						>
							Bezpečně odhlásit
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
}
