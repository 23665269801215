import React from "react";
import { Container } from "react-bootstrap";
import Phone from "@/Components/Icons/Phone.jsx";
import "./Contact.scss";

export default function Contact() {
	return (
		<div className="contact">
			<Container className="flex-column flex-sm-row justify-content-start align-items-start">
				<div className="d-flex align-items-center me-sm-6">
					<span className="contact__dot me-2"></span>
					<span className="fw-bold">Jsme k dispozici</span>
				</div>
				<span className="d-none d-xl-block me-md-4">
					Nevíte si s něčím rady? Napište do chatu nebo volejte Davidovi:
				</span>
				<div className="d-flex align-items-center me-md-4">
					<Phone width={16} height={16} className="me-2" />
					<a href="tel:+420704784507" className="fw-bold">
						+420 704 784 507
					</a>
				</div>
			</Container>
		</div>
	);
}
