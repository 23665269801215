import React from "react";
import { Container } from "react-bootstrap";
import { Link, usePage } from "@inertiajs/react";
import Stepper from "@/Components/Stepper/Stepper.jsx";
import "./Header.scss";
import useBreakpoint from "@/Hooks/useBreakpoint.js";

export default function Header() {
	const { url } = usePage();
	const isMobile = useBreakpoint();
	return (
		<div className="header">
			<Container>
				<Link href="/" className="d-block">
					<img
						src="/images/posledniveta.svg"
						width="200"
						height="25"
						alt="Poslední věta logo"
					/>
				</Link>

				{!(isMobile && url === "/prihlaseni") && <Stepper />}
			</Container>
		</div>
	);
}
